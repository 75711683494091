import { useAppDispatch, useAppSelector } from 'src/store/store';
import StyledSyllabus, { StyledLottie } from './Syllabus.styles';
import Task from './components/Task/Task';
import { useEffect } from 'react';
import thunks from '../Internship/store/thunks';
import empty from './empty.json';
import { useLottie } from 'lottie-react';
import { getInternFilteredPlan } from '../Internship/utils/selectors';

const Syllabus = () => {
  const options = {
    animationData: empty,
    loop: true,
    autoplay: true,
  };
  const taskState = useAppSelector(getInternFilteredPlan);
  const dispatch = useAppDispatch();
  const { View } = useLottie(options);
  useEffect(() => {
    dispatch(thunks.getUserPlan());
  }, [dispatch]);

  if (!taskState || taskState.length === 0) {
    return (
      <StyledLottie>
        <div className="container-lottie">
          {View}
          <p>Скоро у Тебя появятся задания</p>
        </div>
      </StyledLottie>
    );
  }
  return (
    <StyledSyllabus>
      <div className="tasks-holder">
        <div className="nav-header">
          <h1 className="header-syllabus">УЧЕБНЫЙ ПЛАН</h1>
        </div>
        <div className="tasks">
          {taskState &&
            taskState.map((task) => (
              <Task
                key={task.taskJob.taskJobId}
                task={task.taskJob}
                planTask={task}
              />
            ))}
        </div>
      </div>
    </StyledSyllabus>
  );
};

export default Syllabus;
