import PropTypes from 'prop-types';

import { userRolesList, userStatusesList } from './constants';

export const UserType = PropTypes.shape({
  avatar: PropTypes.string,
  avatarThumbnail: PropTypes.string,
  createdAt: PropTypes.string,
  dob: PropTypes.string,
  educationEn: PropTypes.string,
  educationRu: PropTypes.string,
  email: PropTypes.string,
  firstNameEn: PropTypes.string,
  firstNameRu: PropTypes.string,
  id: PropTypes.number,
  info: PropTypes.string,
  lastNameEn: PropTypes.string,
  lastNameRu: PropTypes.string,
  login: PropTypes.string,
  phone: PropTypes.string,
  repo: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.oneOf([null])]),
  slackName: PropTypes.string,
  status: PropTypes.oneOf(userStatusesList),
  role: PropTypes.oneOf(userRolesList),
});

export const TechnologyType = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  groupId: PropTypes.number,
});

export const CrmTaskType = PropTypes.shape({
  additional_info_data: PropTypes.arrayOf(PropTypes.string),
  additional_info_field: PropTypes.arrayOf(PropTypes.string),
  archive: PropTypes.bool,
  budget: PropTypes.string,
  comment: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.number,
  lid_company: PropTypes.string,
  lid_contact_name: PropTypes.string,
  lid_email: PropTypes.string,
  lid_phone: PropTypes.string,
  lid_skype: PropTypes.string,
  lid_time_zone: PropTypes.number,
  project_folder_path: PropTypes.string,
  proposal_link: PropTypes.string,
  subscription: PropTypes.arrayOf([PropTypes.number]),
  task_in_column: PropTypes.number,
  technologies: PropTypes.arrayOf(TechnologyType),
  title: PropTypes.string,
});

export const RequestChangesType = PropTypes.shape({
  email: PropTypes.string,
  title: PropTypes.string,
  comment: PropTypes.string,
  type: PropTypes.string,
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  status: PropTypes.string,
  deniedComment: PropTypes.string,
  rest_days_number: PropTypes.number,
  is_unpaid: PropTypes.bool,
  willCompensateHours: PropTypes.bool,
});

export const RequestHistoryUserType = PropTypes.shape({
  login: PropTypes.string,
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  firstName_ru: PropTypes.string,
  lastName_ru: PropTypes.string,
});

export const RequestHistoryType = PropTypes.shape({
  id: PropTypes.number,
  userId: PropTypes.number,
  requestId: PropTypes.number,
  changes: RequestChangesType,
  createdAt: PropTypes.string,
  user: RequestHistoryUserType,
});

export const RequestType = PropTypes.shape({
  id: PropTypes.id,
  title: PropTypes.string,
  comment: PropTypes.string,
  type: PropTypes.oneOf(['technical', 'vacation', 'medical', 'dayOff', 'common', 'documents']),
  status: PropTypes.oneOf(['wait', 'completed', 'denied', 'inProgress', 'accept']),
  dateFrom: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  dateTo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  date: PropTypes.arrayOf(PropTypes.string),
  rest_days_number: PropTypes.number,
  updated_by: PropTypes.number,
  createdAt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  is_unpaid: PropTypes.bool,
  willCompensateHours: PropTypes.bool,
  user: PropTypes.arrayOf(UserType),
  admin_who_updated_id: UserType,
});

export const RouterPropType = PropTypes.objectOf(PropTypes.any);

export const SelectOptionType = PropTypes.shape({
  label: PropTypes.node,
  value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
});

export const ArticleType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  added_by: PropTypes.number.isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
  })).isRequired,
});

export const extraObjectType = PropTypes.shape({
  createdAt: PropTypes.string,
  date: PropTypes.string,
  description: PropTypes.string,
  end: PropTypes.string,
  durationMinutes: PropTypes.number,
  id: PropTypes.number,
  isProcessed: PropTypes.bool,
  isToggleForCounting: PropTypes.bool,
  project_id: PropTypes.number,
  start: PropTypes.string,
  updatedAt: PropTypes.string,
  user: UserType.isRequired,
  user_id: PropTypes.number,
});

export const ExtraHoursValidationType = PropTypes.shape({
  date: PropTypes.string,
  hour: PropTypes.number,
  minute: PropTypes.number,
  author: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
  }),
  description: PropTypes.string,
});
